<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Müşteri Cihaz Onarım Talepleri</h5>
        <div>
          <button
            v-if="datasource.elements.btn_definition_create.visible"
            v-on:click="showDefinitionModal(null)"
            class="btn btn-sm btn-primary"
          >
            Yeni
          </button>
        </div>
      </div>
      <div class="card-bodyx pt-2x">
        <b-table responsive :items="items" :fields="fields" class="mb-0">
          <template #cell(updated)="data">
            <div v-if="data.value != null">
              <div>{{ data.value.updated_by_name }}</div>
              <div>
                {{ moment(data.value.updated_at).format("DD.MM.YYYY HH:mm") }}
              </div>
            </div>
          </template>
          <template #cell(is_active)="data">
            <span v-if="data.item.is_active == 1">Evet</span>
            <span v-if="data.item.is_active == 0">Hayır</span>
          </template>

          <template #cell(action)="data" class="text-right">
            <div class="text-right">
              <button
                class="btn btn-sm"
                v-on:click="showDefinitionModal(data.value)"
              >
                <feather-icon icon="Edit3Icon" />
              </button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      v-model="datasource.definition.show_modal"
      id="modaldefinition"
      centered
      title-class="pt-1 pb-1"
      footer-class="justify-content-start pl-1"
    >
      <template #modal-title>
        <span v-if="datasource.definition.definition_id == 0"
          >Yeni Tanımlama</span
        >
        <span v-if="datasource.definition.definition_id > 0"
          >Tanımlama Bilgileri</span
        >
      </template>
      <div class="form-group">
        <label class="font-weight-boldd">Tanımlama</label>
        <input
          v-model="datasource.definition.name"
          type="text"
          class="form-control"
          required
          minlength="3"
        />
      </div>
      <div class="form-group" v-if="false">
        <label class="font-weight-boldd">Tanımlama Kodu</label>
        <input
          v-model="datasource.definition.attr_1"
          type="text"
          class="form-control"
          required
          minlength="3"
        />
      </div>
      <div class="form-group">
        <label class="font-weight-boldd">Aktif Mi</label>
        <v-select
          :options="utils.getYesNo()"
          :reduce="(option) => option.value"
          v-model="datasource.definition.is_active"
          :searchable="false"
          :clearable="true"
        />
      </div>
      <template #modal-footer>
        <button
          v-if="
            datasource.elements.btn_definition_update.visible ||
            datasource.elements.btn_definition_create.visible
          "
          v-on:click="definitionSave()"
          class="btn btn-primary"
        >
          Kaydet
        </button>
        <button
          v-if="datasource.elements.btn_definition_delete.visible"
          v-on:click="confirmDeleteDefinition()"
          class="btn btn-danger"
        >
          Sil
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BTable, BModal } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import moment from "moment";
import api from "@/service/api";
import utils from "@/service/utils";

export default {
  components: {
    BTable,
    BModal,
    vSelect,
  },
  data() {
    return {
      utils: utils,
      datasource: {
        elements: {
          btn_definition_delete: { visible: false },
          btn_definition_update: { visible: false },
          btn_definition_create: { visible: false },
        },
        brands: [],
        definition: {
          show_modal: false,
          name: "",
          definition_id: 0,
          is_active: 0,
        },
        yesno: [],
      },
      fields: [
        {
          key: "brand",
          label: "Tanımlama",
          sortable: true,
        },

        {
          key: "is_active",
          label: "Aktif Mi",
          sortable: true,
        },
        {
          key: "updated",
          label: "Güncellenme",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
    };
  },
  created() {
    if (!utils.userHasRole("view_definition")) {
      this.$router.push("/not-found");
      return;
    }
    this.datasource.elements.btn_definition_delete.visible = false;
    this.datasource.elements.btn_definition_update.visible =
      utils.userHasRole("update_definition");
    this.datasource.elements.btn_definition_create.visible =
      utils.userHasRole("create_definition");
    this.getDefinitions();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    userHasRole(rolename) {
      return utils.userHasRole(rolename);
    },
    getDefinitions() {
      api
        .action("getDefinitions", { type: "customer_device_request" })
        .then((q) => {
          if (q.success) {
            this.datasource.brands = q.data;
            this.items = [];
            q.data.forEach((item) => {
              this.items.push({
                brand: item.name,
                attr_1: item.attr_1,
                is_active: item.is_active,
                updated: item.updated_props,
                action: item,
              });
            });
          }
        });
    },
    definitionSave() {
      if (this.datasource.definition.name.length < 2) {
        this.toast("warning", "Bilgi", "Lütfen geçerli bir tanmlama yazın.");
      }
      let postData = this.datasource.definition;
      if (this.datasource.definition.definition_id == 0) {
        delete postData.definition_id;
        postData.type = "customer_device_request";
        postData.action = "createDefinitionCustomerDeviceRequest";
      } else {
        postData.action = "updateDefinitionCustomerDeviceRequest";
      }
      api
        .action(postData.action, {
          ...postData,
        })
        .then((q) => {
          if (q.success) {
            this.toast("success", "Bilgi", q.message);
            this.getDefinitions();
          } else {
            this.toast("warning", "Bilgi", q.message);
          }
        });
    },
    confirmDeleteDefinition(item) {
      this.$swal({
        title: "Tanımlamayı Sil",
        text: "Bu tanımlamaya ait kayıtlar etkilenebilir. Tanımlamayı silmek istediğinizden emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Onayla",
        cancelButtonText: "İptal",
        customClass: { ...utils.defaultSweetStyle },
        buttonsStyling: false,
      }).then((q) => {
        if (q.isConfirmed) {
          api
            .action("deleteDefinition", {
              definition_id: this.datasource.definition.definition_id,
            })
            .then((q) => {
              if (q.success) {
                this.toast("success", "Bilgi", q.message);
                this.getDefinitions();
                this.$bvModal.hide("modaldefinition");
              } else {
                this.toast("warning", "Bilgi", q.message);
              }
            });
        }
      });
    },
    showDefinitionModal(item) {
      if (item == null) {
        this.datasource.definition.definition_id = 0;
        this.datasource.definition.name = "";
        this.datasource.definition.attr_1 = "";
        this.datasource.definition.is_active = 0;
        this.datasource.definition.show_modal = true;
        return;
      }

      this.datasource.definition.definition_id = item.definition_id;
      this.datasource.definition.name = item.name;
      this.datasource.definition.attr_1 = item.attr_1;
      this.datasource.definition.is_active = parseInt(item.is_active);
      this.datasource.definition.show_modal = true;
    },
    toast(type = "warning", title = "", text = "") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: title,
          icon: "AlertCircleIcon",
          variant: type,
          text: text,
        },
      });
    },
  },
};
</script>

<style>
</style>